import { Layout } from 'antd';
import Main from '../Layout/Main';
import Menu from './Menu';
import Logo from '@admin/assets/svg/logo.svg';
import LayoutHeader from './Header';

const { Sider } = Layout;

const Container = () => {
    return (
        <Layout className="w-screen h-screen" style={{ overflow: 'hidden' }}>
            <Sider width="18rem">
                <div className="flex justify-center items-center pt-8 pb-6">
                    <img src={Logo} alt="logo" />
                </div>
                <Menu />
            </Sider>

            <Layout>
                <LayoutHeader />
                <Main />
            </Layout>
        </Layout>
    );
};

export default Container;

export const Component = Container;
Component.displayName = 'Layout';
