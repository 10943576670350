import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const Main = () => {
  return (
    <Content className="flex flex-col overflow-auto px-[16px] pb-[12px]">
      <Outlet />
    </Content>
  );
};

export default Main;
