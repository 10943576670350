import { Layout, Breadcrumb, Dropdown } from 'antd';
import React, { useState, useEffect } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import Setting from '@admin/assets/svg/setting.svg';
import './index.less';
import store from 'store';
import ChangePassword from './ChangePassword';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '@admin/store/app';

const { Header } = Layout;

const LayoutHeader: React.FC = () => {
    const match = useMatches();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { routes } = useAppStore();
    const [showChangePassword, setShowChangePassword] = useState(false);

    // const BreadcrumbItems = match.filter(({ data: { index } }) => !index).map(({ data: { title } }) => ({ title: t(`menu.${title}`) }));

    const BreadcrumbItems = match.reduce<{ title: string }[]>((prev, curr) => {
        const { pathname } = curr;
        const found = routes.find(({ path }) => path === pathname);

        if (found && found.title) {
            const key = `menu.${found.title}`;

            prev.push({ title: t(key) });
        }

        return prev;
    }, []);

    const onHeaderSetting = () => {};

    const onChangePassword = () => {
        setShowChangePassword(true);
    };

    const onExit = () => {
        store.set('token');
        navigate('/login');
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div className="toolItem" onClick={onChangePassword}>
                    {t('btn.modify pdw')}
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div className="toolItem" onClick={onExit}>
                    {t('btn.exit')}
                </div>
            )
        }
    ];

    return (
        <Header className="!bg-transparent !p-[12px] !h-auto">
            <Breadcrumb items={BreadcrumbItems} separator=">" />
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
                <img src={Setting} alt="" className="header-set" onClick={onHeaderSetting} />
            </Dropdown>
            {showChangePassword ? (
                <ChangePassword
                    onClose={() => {
                        setShowChangePassword(false);
                    }}
                />
            ) : null}
        </Header>
    );
};

export default LayoutHeader;
