import React, { Suspense, FC, ReactNode, PropsWithChildren } from 'react';
import { useAppStore } from '@admin/store/app';

interface PermissionProps {
    code?: string;
}

const Permission: FC<PropsWithChildren<PermissionProps>> = props => {
    const { children, code } = props;
    const { userInfo } = useAppStore();

    if (!userInfo) return null;

    const { permissions } = userInfo;

    if (typeof code === 'undefined' || permissions.includes(code)) {
        return <>{children}</>;
    }

    return <div>403...</div>;
};

function PermissionRoute(Component: React.LazyExoticComponent<FC>, code?: string): ReactNode {
    return (
        <Permission code={code}>
            <Suspense fallback={null}>
                <Component />
            </Suspense>
        </Permission>
    );
}

export function UnPermissionRoute(Component: React.LazyExoticComponent<FC>) {
    return (
        <Suspense fallback={null}>
            <Component />
        </Suspense>
    );
}

PermissionRoute.propTypes = {};

export default PermissionRoute;
