import { useState, useMemo } from 'react';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mainRoutes } from '../../router';
import { useAppStore } from '@admin/store/app';

const LayoutMenu = () => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const { pathname } = useLocation();
    const { userInfo, routes } = useAppStore();
    const [selectKeys, setSelectKeys] = useState([pathname]);
    const [openKeys, setOpenKeys] = useState();

    const permissionsMap = useMemo(
        () =>
            routes.reduce((prev, curr) => {
                if (curr.permission) {
                    return { ...prev, [curr.permission]: curr };
                }
                return prev;
            }, {}),
        [routes]
    );

    const permissions = useMemo(() => {
        if (userInfo) {
            const userPermissions = userInfo.permissions;

            let allPermissions = [];

            userPermissions.forEach(permissionKey => {
                allPermissions.push(permissionKey);

                const menu = permissionsMap[permissionKey];

                if (menu && menu.permissionParents) {
                    allPermissions = [...allPermissions, ...menu.permissionParents];
                }
            });

            return allPermissions;
        }

        return [];
    }, [permissionsMap, userInfo]);

    const handleSelect = menu => {
        navigation(menu.key);
        setSelectKeys([menu.key]);
    };

    const handleOpenChange = value => {
        setOpenKeys(value);
    };

    const generateMenu = menus =>
        menus
            .map(menu => {
                const { path, title, children, parentPath, icon: Icon, params, permission } = menu;

                if (permission && !permissions.includes(permission)) return false;

                // if (permission && !permission?.some(item => permissions?.includes(item))) return false;

                // 正则匹配替换 path 后 包含 (:path) 的字段
                const newPath = params
                    ? path.replace(/:(\w+)/g, (_, $1) => {
                          return params?.[$1] || $1;
                      })
                    : path;

                const childrenMenus = children
                    ?.filter(({ index }) => !index)
                    .map(item => ({
                        ...item,
                        parentPath: path
                    }));

                return {
                    key: parentPath ? `${parentPath}/${newPath}` : newPath,
                    label: t(`menu.${title}`),
                    icon: Icon ? <Icon /> : <div className="w-1 h-1 !min-w-[4px] ml-[3.2rem] !min-h-[4px] bg-white rounded-full" />,
                    children: childrenMenus?.length ? generateMenu(childrenMenus) : null
                };
            })
            .filter(Boolean);

    const menus = generateMenu(mainRoutes);

    return (
        <Menu
            selectedKeys={selectKeys}
            openKeys={openKeys}
            mode="inline"
            theme="dark"
            items={menus}
            onSelect={handleSelect}
            onOpenChange={handleOpenChange}
            style={{
                overflowY: 'auto',
                height: 'calc(100% - 113px)'
            }}
        />
    );
};

export default LayoutMenu;
