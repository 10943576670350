import { i18next } from '../i18n';
import { Base64 } from 'js-base64';
import md5 from 'md5';

/**
 * 服务端Url
 * @returns
 */
export const serverUrl = () => {
  return import.meta.env.VITE_SERVER_URL;
};

/**
 * 延迟时间
 * @param path
 * @returns {Promise<unknown>}
 */
export const waitTime = path => {
  return new Promise(resolve => setTimeout(() => resolve(path), 1000));
};

/**
 * 在外部 js/ts 中使用翻译方法
 * @param key 翻译字段
 */
export const translation = key => {
  if (!key) {
    throw new Error('关键字不能为空');
  }

  return i18next.t(key);
};


/**
 * password crypto
 * @param str pwd
 * @returns
 */
export const cryptoStr = (str = '') => {
  return Base64.encode(md5(str));
};

// md5 pwd
export const md5Str = (str = '') => {
  return md5(str);
};
