import { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Checkbox, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import store from 'store';
import { t } from 'i18next';
import { zhandain_mock } from './mock';
import LoginBg from '@admin/assets/svg/loginBg.svg';
import { login, getTreeData } from '@admin/api/Auth';
import './style.less';
import { cryptoStr } from '@admin/utils/tools/base';
import { useAppStore } from '@admin/store/app';

const Login = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const { setUserInfo } = useAppStore();
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);
    const onChange = e => {
        setRemember(e.target.checked);
    };

    const generateData = ({ data, children }) => {
        let childrenList = [];

        if (children && children.length > 0) {
            childrenList = children.map(child => {
                return generateData(child);
            });
        }
        return {
            title: data.permissionDesc,
            key: data.permissionId,
            children: childrenList
        };
    };
    const selectTreeKey = (treeData, keyList) => {
        const res = [];

        if (!keyList.includes(treeData.key)) {
            if (treeData.children && treeData.children.length > 0) {
                treeData.children.forEach(child => {
                    if (keyList.includes(child.key)) {
                        res.push(treeData.key);
                    }
                    if (child.children && child.children.length > 0) {
                        const childrens = selectTreeKey(child, keyList);

                        res.push(...childrens);
                    }
                });
            }
        }
        return [...new Set(res)];
    };
    const onLogin = () => {
        setLoading(true);

        if (loading) return;

        form.validateFields()
            .then(async () => {
                const { account, pwd } = form.getFieldsValue();
                const { errorCode, body } = await login({
                    account,
                    pwd: cryptoStr(pwd)
                });

                if (errorCode === 0) {
                    setUserInfo(body);

                    store.set('token', body?.token);
                    // 登录请求成功之后获取权限树
                    const { errorCode: errorCode1, body: body1 } = await getTreeData();
                    let treeData = {};

                    if (errorCode1 === 0) {
                        // 根据权限树生成权限对象
                        treeData = generateData(body1?.root);
                    }
                    if (remember) {
                        store.set('account', account);
                        store.set('pwd', pwd);
                    } else {
                        store.set('account');
                        store.set('pwd');
                    }
                    store.set('remember', remember);
                    let permissionIds = body.permissions;

                    // 根据登录请求返回的用户的页面权限点来添加菜单权限点
                    // 解决登陆之后虽然有页面权限,但因为没有菜单权限导致看不见页面的问题
                    permissionIds.push(...selectTreeKey(treeData, body.permissions));
                    permissionIds = [...new Set(permissionIds)];
                    store.set('permissions', permissionIds);
                    messageApi.success(t('tips.login success'), [1.2], () => {
                        navigate('/home');
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (store.get('remember') && store.get('account') && store.get('pwd')) {
            form.setFieldsValue({
                account: store.get('account'),
                pwd: store.get('pwd')
            });
            setRemember(store.get('remember'));
        }
    }, [form]);

    return (
        <div className="login bg-no-repeat flex">
            {contextHolder}
            <img src={LoginBg} alt="" className="loginBg" />
            <div className="form">
                <div className="title text-primary font-bold  text-center">Iot Platform</div>
                <Form name="login" layout="vertical" form={form} autoComplete="off">
                    <Form.Item
                        name="station"
                        className="loginFormItem"
                        initialValue="china"
                        rules={[
                            {
                                required: true,
                                message: t('placeholder.please select stations')
                            }
                        ]}>
                        <Select size="large" style={{ height: '100%' }} options={zhandain_mock} />
                    </Form.Item>
                    <Form.Item
                        name="account"
                        className="loginFormItem"
                        rules={[
                            {
                                required: true,
                                message: t('placeholder.please enter account')
                            }
                        ]}>
                        <Input onPressEnter={onLogin} size="large" placeholder={t('placeholder.account')} />
                    </Form.Item>
                    <Form.Item
                        name="pwd"
                        className="loginFormItem"
                        rules={[
                            {
                                required: true,
                                message: t('placeholder.please enter pwd')
                            }
                        ]}>
                        <Input.Password onPressEnter={onLogin} size="large" placeholder={t('placeholder.password')} />
                    </Form.Item>
                    <Form.Item>
                        <div className="funcLine flex justify-between">
                            <Checkbox checked={remember} onChange={onChange}>
                                {t('btn.remember me')}
                            </Checkbox>
                            <div className="forget text-assit cursor-pointer">{t('btn.forget password')}</div>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" className="w-full btn text-placeholder" onClick={onLogin} loading={loading}>
                            {t('btn.login')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;

export const Component = Login;
Component.displayName = 'Login';
