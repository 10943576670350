import { createGlobalStore } from 'hox';
import { useState } from 'react';
import { webSiteColors } from '@admin/utils/customize';
import type { Route } from '@admin/router';

/**
 * 第一个元素是用来订阅 store 的 Hook 函数
 * 第二个元素是一个静态获取函数  可以在任何地方使用(js/ts/jsx/tsx)
 * TODO: 使用方法  const {themeParams} = getAppStore();
 */
export const [useAppStore, getAppStore] = createGlobalStore(() => {
    const [userInfo, setUserInfo] = useState<{ permissions: string[]; admin: boolean } | null>(null);
    const [isLogin, setIsLogin] = useState(false);
    const [routes, setRoutes] = useState<Route[]>([]);

    const [themeParams, setThemeParams] = useState({
            colorPrimary: webSiteColors()
        }),
        themeConfig = () => {
            setThemeParams(Object.assign({}, themeParams));
        };

    return {
        isLogin,
        userInfo,
        themeParams,
        routes,
        themeConfig,
        setUserInfo,
        setIsLogin,
        setRoutes
    };
});
